<script>
export default {};
</script>

<template>
	<div
		class="scroll-button bg-green block rounded-full flex justify-center items-center cursor-pointer"
	>
		<font-awesome-icon class="text-white text-xl" icon="fa-solid fa-arrow-down" />
	</div>
</template>

<style lang="scss" scoped>
.scroll-button {
	border: 3px solid #d6e2f0;
	width: 40px;
	height: 40px;
	box-shadow: 0px 7px 14px rgba($color: #7ca9bd, $alpha: 0.75);
}
</style>
